const PTApplePodcastEmbed = ({
  value
}: {
  value: {
    url: string;
  };
}) => {
  const {
    url
  } = value;
  const path = url?.split('https://embed.podcasts.apple.com')?.[1];
  const styles = {
    width: "100%",
    maxWidth: "660px",
    overflow: "hidden",
    borderRadius: "10px",
    transform: "translateZ(0px); animation: 2s ease 0s 6 normal none running loading - indicator",
    backgroundColor: "#e4e4e4"
  };
  return <iframe id="embedPlayer" src={`https://embed.podcasts.apple.com${path}`} height="175px" frameBorder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style={styles} data-sentry-component="PTApplePodcastEmbed" data-sentry-source-file="applePodcastEmbed.tsx">
		</iframe>;
};
export { PTApplePodcastEmbed };