import SanityAudio from "components/helper/sanity-audio";

const PTAudioFile = node => {
	const { audioFile } = node.value

	return !!audioFile && <SanityAudio
		options={{ playerMode: "mini-player", hasImage: !!audioFile.image }}
		darkMode={true}
		tracks={[audioFile]}
	/>
}

export default PTAudioFile;