import PropTypes from "prop-types";
import React from "react";
import PlayIcon from './svg/play-icon.jsx';
import PauseIcon from './svg/pause-icon.jsx';
import styles from "./styles/audio-player.module.scss";
const PlayPauseButton = props => {
  return <button className={styles.play} aria-label="Play/Pause Toggle" onClick={props.togglePlaying} data-sentry-component="PlayPauseButton" data-sentry-source-file="play-pause-button.jsx">
		<span className={styles.playIcon}><PlayIcon data-sentry-element="PlayIcon" data-sentry-source-file="play-pause-button.jsx" /></span>
		<span className={styles.pauseIcon}><PauseIcon data-sentry-element="PauseIcon" data-sentry-source-file="play-pause-button.jsx" /></span>
	</button>;
};
PlayPauseButton.propTypes = {
  togglePlaying: PropTypes.func
};
export default PlayPauseButton;