import { SanityThreeColumnTable } from "@metmuseum/types";
import styles from "./three-column-table.module.scss";
import RichTextInline from "components/rich-text/rich-text-inline-only";
import { Fragment } from "react";
type Props = {
  value: SanityThreeColumnTable;
};
const ThreeColumnTable = (node: Props) => {
  const {
    column1,
    column2,
    column3,
    rows
  } = node.value;
  return <div className={styles.threeColumnTable} data-sentry-component="ThreeColumnTable" data-sentry-source-file="index.tsx">
			<div className={styles.columnHeader}>{column1}</div>
			<div className={styles.columnHeader}>{column2}</div>
			<div className={styles.columnHeader}>{column3}</div>
			{rows.map(row => {
      const {
        column1,
        column2,
        column3
      } = row;
      return <Fragment key={row._key}>
						<div className={styles.columnRow}>{column1}</div>
						<div className={styles.columnRow}>{column2}</div>
						<div className={styles.columnRow}><RichTextInline value={column3} /></div>
					</Fragment>;
    })}
		</div>;
};
export default ThreeColumnTable;