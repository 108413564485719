import PropTypes from "prop-types"
import SanityImage from "@sanity-image";
import Link from "next/link";
import styles from 'components/rich-text/pt-components/pt-components.module.scss';
import RichTextInline from "components/rich-text/rich-text-inline-only";

/*
	The default sizes set match the way images will appear in most of our components,
	specifically built around the ContentSplit component.
	For readability reasons RichText won't exceed those sizes (confirmed by Julie)

	Unfortunately we cant use CSS vars for mediaqueries so this is just kind of a magic string.
*/
const PTImage = ({ value }) => {
	if (!value?.asset?._ref || !value) {
		return null
	}

	return (
		<figure className={`${value.extraLarge ? "extraLarge" : null} ${styles.figure}`}>			
			{value.link ? (
				<Link href={value.link}>
					<SanityImage
						alt={value.alt}
						sizes="(max-width: 960px) 85vw, 1200px"
						image={value} />
				</Link>) :
				(
					<SanityImage
						alt={value.alt}
						sizes="(max-width: 960px) 85vw, 1200px"
						image={value} />
				)
			}

			{ value.imageCaption  && (
				<figcaption className={styles.imgCaption}>
					<RichTextInline value={value.imageCaption} />
				</figcaption>						
			)}
			
		</figure>
	);
}

PTImage.propTypes = {
	value: PropTypes.shape({
		alt: PropTypes.string,
		extraLarge: PropTypes.bool,
		imageCaption: PropTypes.string,
		asset: PropTypes.shape({
			_ref: PropTypes.string
		}),
		link: PropTypes.string
	})
}

export default PTImage;
