// use case: 2 or more sponsor logos must share a line
import PropTypes from "prop-types"
import PTImage from "./image"
import styles from "./sponsorLogoRow.module.scss";

const SponsorLogoRow = ({ value }) => {
	return <div className={styles.sponsorLogoRow}>
		{value?.logos?.map(logo => <div
			key={logo._key}
			className={styles.sponsorLogo}>
			<PTImage value={logo} />
		</div>
		)}
	</div>
}

SponsorLogoRow.propTypes = {
	value: PropTypes.shape({
		logos: PropTypes.arrayOf(PropTypes.object)
	})
}

export default SponsorLogoRow
