import styles from "./blocks.module.scss";
const blocks = {
  h1: ({
    children
  }) => <h1 className={styles.h1}>{children}</h1>,
  h2: ({
    children
  }) => <h2 className={styles.h2}>{children}</h2>,
  h3: ({
    children
  }) => <h3 className={styles.h3}>{children}</h3>,
  h4: ({
    children
  }) => <h4 className={styles.h4}>{children}</h4>,
  blockquote: ({
    children
  }) => <blockquote className={styles.blockquote}>{children}</blockquote>
};
export default blocks;