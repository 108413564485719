import condeNast from './condeNast';
import horizontalRule from './horizontalRule';
import PTAudioFile from './PTaudioFile';
import PTAudioPlaylist from './audioPlaylist';
import PTGoogleMapsEmbed from './googleMapsEmbed';
import PTImage from './image';
import PTYoutubePlayer from './youtube';
import SponsorLogoRow from './sponsorLogoRow';
import ProductTable  from "./productTable";
import { PTApplePodcastEmbed } from './applePodcastEmbed';
import { PTSpotifyPlaylistEmbed } from './spotifyPlaylistEmbed';
import ThreeColumnTable from './three-column-table';

const types = {
	applePodcastEmbed: PTApplePodcastEmbed,
	audioFileEmbed: PTAudioFile,
	audioPlaylistEmbed: PTAudioPlaylist,
	condeNast: condeNast,
	googleMapsEmbed: PTGoogleMapsEmbed,
	horizontalRule: horizontalRule,
	image: PTImage,
	productTable: ProductTable,
	sponsorLogoRow: SponsorLogoRow,
	spotifyPlaylist: PTSpotifyPlaylistEmbed,
	threeColumnTable: ThreeColumnTable,
	youtube: PTYoutubePlayer,
}

export default types;
