import SanityAudio from "components/helper/sanity-audio";

const PTAudioPlaylist = node => {
	const { playlist } = node.value;

	return (
		playlist?.audioStops?.length > 0 &&
		<SanityAudio
			exhibitionId={playlist?.exhibition?._id}
			exhibitionTitle={playlist?.exhibition?.title}
			options={{ playerMode: "mini-player" }}
			darkMode={true}
			// TODO: this should just be renamed 'tracks' not audioStops
			tracks={playlist.audioStops}
		/>
	)
}

export default PTAudioPlaylist;
