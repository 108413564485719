"use client";

import PropTypes from "prop-types";
import Image from 'next/image';
import { sanityClient } from 'lib/sanity.server';
import { useNextSanityImage } from 'next-sanity-image';
import styles from './sanity-image.module.scss';
const SanityImage = ({
  image,
  className = "",
  sizes = "100vw",
  priority = false,
  quality = 75,
  placeholder = "empty",
  fill = false,
  style
}) => {
  // It just uses the projectId and dataset from this "client" to make image URLs,
  //so I left the context "empty" for now,
  //so as not to imply this should be set to any particular value.
  const imageProps = useNextSanityImage(sanityClient({}), image);
  if (!image) {
    return null;
  }
  const maxWidth = image.maxWidth ? {
    maxWidth: `${image.maxWidth}px`
  } : {};
  const imageSizes = image.maxWidth ? `${image.maxWidth}px` : sizes;
  const colorStyles = image.palette ? {
    "--img-bg-color": image.palette.background,
    "--img-color": image.palette.foreground
  } : {};
  const defaultCSS = {
    maxWidth: '100%',
    height: 'auto',
    ...maxWidth,
    ...colorStyles,
    ...style
  };
  const defaultFillCSS = {
    objectFit: "cover",
    ...maxWidth,
    ...colorStyles,
    ...style
  };
  const handleImageLoaded = event => {
    event?.target?.removeAttribute("data-loading");
  };
  if (!image?.palette?.foreground && process.env.NODE_ENV === "development") {
    console.warn(`An Image on this page was not resolved using groqImageResolver \n
		This means alt text authored in the media library is ignored, \n
		and the image won't have the correct loading background color.`);
  }
  return <Image className={`${styles.img} ${className}`} alt={image?.alt || " "} style={fill ? defaultFillCSS : defaultCSS} {...imageProps} fill={fill} quality={quality} data-loading={true} placeholder={placeholder} priority={priority} sizes={imageSizes} onLoad={handleImageLoaded} width={fill ? 0 : imageProps.width} height={fill ? 0 : imageProps.height} data-sentry-element="Image" data-sentry-component="SanityImage" data-sentry-source-file="index.jsx" />;
};
SanityImage.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.bool,
  image: PropTypes.shape({
    alt: PropTypes.string,
    maxWidth: PropTypes.number,
    palette: PropTypes.shape({
      background: PropTypes.any,
      foreground: PropTypes.any
    })
  }),
  placeholder: PropTypes.string,
  priority: PropTypes.bool,
  quality: PropTypes.number,
  sizes: PropTypes.string,
  style: PropTypes.any
};
export default SanityImage;