import PropTypes from "prop-types";
import styles from "./productTable.module.scss";
const ProductTable = ({
  value
}) => {
  const {
    rows
  } = value;
  return <div className={styles.table} data-sentry-component="ProductTable" data-sentry-source-file="index.jsx">
			{rows?.map(row => <div key={row.name} className={styles.row}>
					{row.name}<b>{row.price}</b>
				</div>)}
		</div>;
};
ProductTable.propTypes = {
  value: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.string
    }))
  })
};
export default ProductTable;