import React from "react";
import PropTypes from 'prop-types';
import PlaylistTrack from "./playlistTrack.jsx";
import { useTranslations } from "next-intl";
import styles from "./styles/audio-player.module.scss";
const Playlist = props => {
  const t = useTranslations();
  return <ol className={styles.playlist} data-sentry-component="Playlist" data-sentry-source-file="playlist.jsx">
		{props.tracks.length && <h4 className={styles.playlistTitle}>{t('playlist')}</h4>}
		{props.tracks.map(playlistTrack => <PlaylistTrack isActive={playlistTrack.id === props.currentTrack.id} {...playlistTrack} handleTrackChange={props.handleTrackChange} key={playlistTrack.id} />)}
	</ol>;
};
Playlist.propTypes = {
  currentTrack: PropTypes.object.isRequired,
  handleTrackChange: PropTypes.func.isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({
    ...PlaylistTrack.propTypes,
    isActive: PropTypes.bool,
    handleTrackChange: PropTypes.func
  })).isRequired
};
export default Playlist;