import getYouTubeId from 'get-youtube-id'
import YoutubePlayer from "components/media/youtube-player"

const PTYoutubePlayer = node => {
	const { url, autoplay, loop, controls } = node.value;
	const id = getYouTubeId(url);
	return <YoutubePlayer 
		youtubeId={id}
		autoplay={autoplay}
		loop={loop}
		controls={controls}
	/>
}

export default PTYoutubePlayer;