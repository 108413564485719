import PropTypes from "prop-types";
import styles from "./condeNast.module.scss";

const is24Chars = /^[a-z0-9]{24}$/;

const CondeNast = props => {
	const { value } = props;
	const { videoID } = value;

	const videoUrl = `//player.cnevids.com/iframe/video/${videoID}?iu=%2F3379%2Fconde.vogue%2Fplayer&adsDisabled=true&autoplay=false`;
	
	if (!is24Chars.test(videoID)) {
		return;
	}

	return (
		<div>
			<iframe allowFullScreen="true" allow="fullscreen" src={videoUrl} className={styles.condeNastPlayer} style={{width: "100%"}}></iframe>
		</div>
	)
}

CondeNast.propTypes = {
	value: PropTypes.shape({
		videoID: PropTypes.string
	})
}

export default CondeNast