import list from "./list";
import marks from "./marks";
import types from "./types";
import blocks from "./blocks";

const ptComponents = {
	marks: marks,
	list: list,
	types: types,
	block: blocks
}

export default ptComponents;
