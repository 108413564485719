const PTSpotifyPlaylistEmbed = ({
  value
}: {
  value: {
    url: string;
  };
}) => {
  const {
    url
  } = value;
  const path = url?.split('https://open.spotify.com')?.[1];
  const styles = {
    width: "100%",
    maxWidth: "660px",
    overflow: "hidden",
    borderRadius: "12px",
    transform: "translateZ(0px); animation: 2s ease 0s 6 normal none running loading - indicator",
    backgroundColor: "#e4e4e4"
  };
  return <iframe id="embedPlayer" src={`https://open.spotify.com${path}`} height="352px" frameBorder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" style={styles} data-sentry-component="PTSpotifyPlaylistEmbed" data-sentry-source-file="spotifyPlaylistEmbed.tsx">
		</iframe>;
};
export { PTSpotifyPlaylistEmbed };