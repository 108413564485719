"use client";

/* eslint-disable @next/next/no-img-element */
import React from "react";
import PropTypes from 'prop-types';
import escape from "lodash/escape";
import timeFormatter from "./time-formatter";
import Playlist from "./playlist.jsx";
import Transcript from "./audio-transcript.jsx";
import PlayPauseButton from "./play-pause-button.jsx";
import ForwardTenSecondsIcon from "./svg/forward-ten-seconds.jsx";
import RewindTenSecondsIcon from "./svg/rewind-ten-seconds.jsx";
import MarkdownParser from "components/rich-text/markdown-parser";
import gtmEventHandler from "lib/gtmEventHandler";
import I8nBadge from "components/audio-guide/I18n-badge";
import styles from "./styles/audio-player.module.scss";
import ScreenReaderOnly from "components/accesibility/screen-reader-only";
import { urlFor } from "helpers/helpers";
const defaultOptions = {
  hasImage: true,
  playerMode: "",
  seekHelperDuration: 10,
  analyticsSender: gtmEventHandler
};
class AudioPlayerClient extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperEl = React.createRef();
    this.audioEl = React.createRef();
    this.progressBarCanvasEl = React.createRef();
    this.scrubStartAreaEl = React.createRef();
    this.scrubbableAreaEl = React.createRef();
    this.exhibitionId = props.exhibitionId;
    this.exhibitionTitle = props.exhibitionTitle;
    this.playerId = props.id;
    this.translations = props.translations;
    this.t = props.t;

    // Options
    this.options = {
      ...defaultOptions,
      ...props.options
    };

    // Analytics
    this.analyticsSender = this.options.analyticsSender;

    // State
    this.state = {
      amountPlayed: 0,
      currentTrack: props.currentTrack || props.tracks[0],
      isPlaying: false,
      isScrubbing: false,
      currentTime: "0:00",
      timeRemaining: "0:00",
      playerMode: this.options.playerMode,
      playedTracks: []
    };
    // Events to Emit
    this.beforeTrackChange = new Event("beforeTrackChange");
    this.afterTrackChange = new Event("afterTrackChange");

    // Bind functions to this object and save the returned function reference
    ["handlePlay", "handlePause", "_handleTimeChange", "calculatePlayAnalytics", "beginScrubbing", "endScrubbing", "handleTrackChange", "handleEnd", "handleTimeChange", "quickSeekBack", "quickSeekForward", "scrub", "drawProgress", "setMetaData", "togglePlaying"].forEach(func => {
      this[func] = this[func]?.bind(this);
    });
  }
  componentDidMount() {
    this.progressBarCanvas = this.progressBarCanvasEl.current.getContext("2d");
    this.scrubStartAreaEl.current.addEventListener("touchstart", this.beginScrubbing, {
      passive: false
    });
    this.scrubStartAreaEl.current.addEventListener("mousedown", this.beginScrubbing);
    setTimeout(() => {
      this.analyticsSender.pushToDataLayer({
        event: "audioPlayer.playerLoaded",
        audioPlayer: {
          exhibitionId: this.state.currentTrack.exhibitionId || this.exhibitionId,
          exhibitionTitle: this.state.currentTrack.exhibitionTitle || this.exhibitionTitle,
          playerId: this.playerId
        }
      });
    }, 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.isPlaying !== this.state.isPlaying) {
      this.state.isPlaying ? this.audioEl.current.play() : this.audioEl.current.pause();
    }
    if (prevProps.options.playerMode !== this.props.options.playerMode) {
      this.setState({
        playerMode: this.props.options.playerMode
      });
    }
  }
  componentWillUnmount() {
    this.scrubStartAreaEl.current.removeEventListener("touchstart", this.beginScrubbing, {
      passive: false
    });
    this.scrubStartAreaEl.current.removeEventListener("mousedown", this.beginScrubbing);
  }
  handleTimeChange() {
    requestAnimationFrame(this._handleTimeChange);
  }
  _handleTimeChange() {
    const duration = isFinite(this.audioEl.current.duration) ? this.audioEl.current.duration : 0;
    const elapsed = isFinite(this.audioEl.current.currentTime) ? this.audioEl.current.currentTime : 0;
    this.setDisplayTime(elapsed, duration);
    this.drawProgress(elapsed, duration);
    this.calculatePlayAnalytics(elapsed, duration);
  }
  handleEnd() {
    this.handleTimeChange();
    this.audioEl.current.pause();
  }
  setDisplayTime(elapsed, duration) {
    this.setState({
      currentTime: timeFormatter(elapsed),
      timeRemaining: timeFormatter(duration - elapsed)
    });
  }
  handleTrackChange(id) {
    // do nothing if current track
    if (id === this.state.currentTrack.id) {
      return false;
    }
    const newTrack = this.props.tracks.find(track => track.id === id);
    this.wrapperEl.current.dispatchEvent(this.beforeTrackChange);
    this.drawProgress(0, 1);
    this.setState({
      amountPlayed: 0,
      currentTrack: newTrack,
      currentTime: "0:00",
      timeRemaining: "0:00"
    }, () => {
      this.audioEl.current.load(); // load the new track, this will fire metadataloaded, btw
      this.audioEl.current.play();
      this.wrapperEl.current.dispatchEvent(this.afterTrackChange);
      this.props.trackChangeCallback && this.props.trackChangeCallback(this.state);
    });
  }
  calculatePlayAnalytics(elapsed, duration) {
    if (elapsed && duration) {
      let currentTenth = Math.round(elapsed / duration * 10) / 10;
      if (this.state.amountPlayed < currentTenth) {
        this.setState({
          amountPlayed: currentTenth
        }, () => {
          this.analyticsSender.pushToDataLayer({
            event: "audioPlayer.trackProgress",
            audioPlayer: {
              exhibitionId: this.state.currentTrack.exhibitionId || this.exhibitionId,
              exhibitionTitle: this.state.currentTrack.exhibitionTitle || this.exhibitionTitle,
              trackId: this.state.currentTrack.id,
              trackTitle: this.state.currentTrack.title,
              playerId: this.state.currentTrack.title,
              // TODO: NOOOOOOO. WHY did we decide this?
              trackProgress: this.state.amountPlayed
            }
          });
        });
      }
    }
  }
  canUpdateAuotmatically() {
    return !this.state.isScrubbing;
  }
  drawProgress(elapsed, duration, width = 10000) {
    this.progressBarCanvas.save();
    this.progressBarCanvas.clearRect(0, 0, width, 6);
    this.progressBarCanvas.fillStyle = "transparent";
    this.progressBarCanvas.fillRect(0, 0, width, 6);
    this.progressBarCanvas.fillStyle = "#333";
    this.progressBarCanvas.fillRect(0, 0, elapsed / duration * width, 6);
    this.progressBarCanvas.restore();
  }
  beginScrubbing(e) {
    e.preventDefault(); // don't fire redundant mouse event, if this was a touch
    console.log("begin scrubbing");
    this.setState({
      isScrubbing: true
    }, () => {
      this.initializeScrubbingListeners();
      this.scrub(e);
    });
  }
  initializeScrubbingListeners() {
    // touch
    this.scrubbableAreaEl.current.addEventListener("touchmove", this.scrub, {
      passive: false
    });
    this.scrubbableAreaEl.current.addEventListener("touchend", this.endScrubbing, {
      passive: false
    });
    this.scrubbableAreaEl.current.addEventListener("touchcancel", this.endScrubbing, {
      passive: false
    });
    // mouse
    this.scrubbableAreaEl.current.addEventListener("mousemove", this.scrub);
    this.scrubbableAreaEl.current.addEventListener("mouseup", this.endScrubbing);
    this.scrubbableAreaEl.current.addEventListener("mouseleave", this.endScrubbing);
  }
  scrub(e) {
    e.preventDefault();
    if (this.state.isScrubbing) {
      console.log("scrubbing");
      let canvasRectangle = this.progressBarCanvasEl.current.getBoundingClientRect();
      let offsetX;
      if (["touchstart", "touchmove"].includes(e.type)) {
        offsetX = e.touches[0].clientX - canvasRectangle.left;
      } else {
        offsetX = e.offsetX;
      }
      let currentSecond = offsetX / canvasRectangle.width * this.audioEl.current.duration;
      this.audioEl.current.currentTime = currentSecond;
    }
  }
  endScrubbing(e) {
    e.preventDefault();
    console.log("done scrubbing");
    this.setState({
      isScrubbing: false
    });
    this.cleanUpScrubListeners();
  }
  cleanUpScrubListeners() {
    // touch
    this.scrubbableAreaEl.current.removeEventListener("touchmove", this.scrub, {
      passive: false
    });
    this.scrubbableAreaEl.current.removeEventListener("touchend", this.endScrubbing, {
      passive: false
    });
    this.scrubbableAreaEl.current.removeEventListener("touchcancel", this.endScrubbing, {
      passive: false
    });
    // mouse
    this.scrubbableAreaEl.current.removeEventListener("mousemove", this.scrub);
    this.scrubbableAreaEl.current.removeEventListener("mouseup", this.endScrubbing);
    this.scrubbableAreaEl.current.removeEventListener("mouseleave", this.endScrubbing);
  }
  setMetaData() {
    if (!("mediaSession" in navigator)) {
      return false;
    }
    let artwork = [];
    let src = urlFor(this.state.currentTrack?.image);
    src && artwork.push({
      src
    });
    navigator.mediaSession.metadata = new MediaMetadata({
      title: this.state.currentTrack.title,
      artist: this.state.currentTrack.description,
      artwork
    });
  }
  togglePlaying(e) {
    if (!this.isPlaying) {
      const audioEls = document ? [...document.querySelectorAll("audio")] : [];
      audioEls?.map(audioEl => audioEl.pause());
    }
    e.preventDefault();
    this.setState({
      isPlaying: !this.state.isPlaying
    });
  }

  // handles play event that may come externally, ie from media api
  handlePlay() {
    this.setState({
      isPlaying: true
    }, () => {
      this.setMetaData();
      // only send trackPlayed analytics if we're not resuming the current track (last playedTrack)
      if (this.state.playedTracks.slice(-1)[0] !== this.state.currentTrack.id) {
        this.analyticsSender.pushToDataLayer({
          event: "audioPlayer.trackStarted",
          audioPlayer: {
            exhibitionId: this.state.currentTrack.exhibitionId || this.exhibitionId,
            exhibitionTitle: this.state.currentTrack.exhibitionTitle || this.exhibitionTitle,
            trackId: this.state.currentTrack.id,
            trackTitle: this.state.currentTrack.title,
            playerId: this.playerId
          }
        });
        this.setState({
          playedTracks: [...this.state.playedTracks, this.state.currentTrack.id]
        });
      }
    });
  }

  // handles pause event that may come externally, ie from media api
  handlePause() {
    this.setState({
      isPlaying: false
    });
  }
  quickSeekBack(e) {
    e.preventDefault();
    const newPosition = Math.max(0, this.audioEl.current.currentTime - this.options.seekHelperDuration);
    this.audioEl.current.currentTime = newPosition; // note: setting this fires timeupdate event ;)
  }
  quickSeekForward(e) {
    e.preventDefault();
    const newPosition = Math.min(this.audioEl.current.duration, this.audioEl.current.currentTime + this.options.seekHelperDuration);
    //Current time can't go below 0.
    this.audioEl.current.currentTime = Math.max(0, newPosition); // note: setting this fires timeupdate event ;)
  }
  render() {
    return <section ref={this.wrapperEl} className={`${styles.audioPlayer} ${this.state.isPlaying ? styles.isPlaying : ""} ${styles[this.state.playerMode]}`} data-sentry-component="AudioPlayerClient" data-sentry-source-file="client-component.jsx">
				<I8nBadge languages={this.translations} enableDarkMode={true} data-sentry-element="I8nBadge" data-sentry-source-file="client-component.jsx" />
				<div className={styles.mediaSection}>
					<div className={styles.imageSection}>
						<div className={styles.imageWrapper}>
							{this.options.hasImage && <img alt={`Cover Image for ${this.state.currentTrack.title}`} className={styles.coverImage} src={urlFor(this.state.currentTrack.image)} />}
						</div>
					</div>
					<div className={styles.body}>
						<div className={styles.headings}>
							<h1 className={styles.title}>
								<MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="client-component.jsx">{this.state.currentTrack.title}</MarkdownParser>
							</h1>
							<h2 className={styles.subtitle}>{" "}{escape(this.state.currentTrack.description)}</h2>
						</div>
						<div className={styles.controlsWrapper}>
							<div className={styles.audioControls}>
								<div className={styles.playWrapper}>
									<PlayPauseButton isPlaying={this.state.isPlaying} togglePlaying={this.togglePlaying} data-sentry-element="PlayPauseButton" data-sentry-source-file="client-component.jsx" />
								</div>
								<div ref={this.scrubbableAreaEl} className={styles.timeControls}>
									<div ref={this.scrubStartAreaEl} className={styles.scrubberWrapper}>
										<canvas ref={this.progressBarCanvasEl} width="10000" height="6" className={styles.progressBar}>
										</canvas>
									</div>
									<div className={styles.timeButtons}>
										<div className={styles.backControls}>
											<span style={{
                      minWidth: "2em"
                    }} className={styles.currentTime}>{this.state.currentTime}</span>
											<button onClick={this.quickSeekBack} className={styles.seekBack}>
												<RewindTenSecondsIcon data-sentry-element="RewindTenSecondsIcon" data-sentry-source-file="client-component.jsx" />
												<ScreenReaderOnly text={this.t("skipBackward")} data-sentry-element="ScreenReaderOnly" data-sentry-source-file="client-component.jsx" />
											</button>
										</div>
										<div className={styles.forwardControls}>
											<button onClick={this.quickSeekForward} className={styles.seekForward} aria-label={this.t("skipForward")}>
												<ForwardTenSecondsIcon data-sentry-element="ForwardTenSecondsIcon" data-sentry-source-file="client-component.jsx" />
												<ScreenReaderOnly text={this.t("skipForward")} data-sentry-element="ScreenReaderOnly" data-sentry-source-file="client-component.jsx" />
											</button>
											<span style={{
                      minWidth: "2em"
                    }} className={styles.remainingTime}>{this.state.timeRemaining}</span>
										</div>
									</div>
								</div>
								<audio ref={this.audioEl} onEnded={this.handleEnd} onPlay={this.handlePlay} onPause={this.handlePause} onTimeUpdate={this.handleTimeChange} className={styles.audioElement}
              // data-track='{JSON.stringify(this.state.track)}'
              title={this.state.currentTrack.title} style={{
                width: "100%",
                height: "36px"
              }} controls>
									<source src={this.state.currentTrack.audio} />
									{/* <!-- TODO: playlist links, too!! --> */}
									{/* <Trans i18nKey="common:audioNotSupported" components={[<p key="not-supported"></p>, <a href={this.state.currentTrack.audio} key="not-supported-link"></a>]} /> */}
								</audio>
							</div>
						</div>
					</div>
				</div>
				<Transcript transcript={this.state.currentTrack.transcript} analyticsSender={this.analyticsSender} data-sentry-element="Transcript" data-sentry-source-file="client-component.jsx" />
				{this.props.tracks.length > 1 && <Playlist currentTrack={this.state.currentTrack} tracks={this.props.tracks} handleTrackChange={this.handleTrackChange} />}
			</section>;
  }
}
AudioPlayerClient.defaultProps = {
  options: {}
};
const trackShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  audio: PropTypes.string.isRequired,
  id: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
});
AudioPlayerClient.propTypes = {
  t: PropTypes.any,
  id: PropTypes.string.isRequired,
  exhibitionId: PropTypes.string,
  exhibitionTitle: PropTypes.string,
  tracks: PropTypes.arrayOf(trackShape).isRequired,
  currentTrack: trackShape,
  // optional, initialize to a specificed track, otherwise first
  options: PropTypes.shape({
    hasImage: PropTypes.bool,
    playerMode: PropTypes.string,
    seekHelperDuration: PropTypes.number
  }),
  trackChangeCallback: PropTypes.func,
  translations: PropTypes.array
};
export default AudioPlayerClient;