import PropTypes from "prop-types"

const PTGoogleMapsEmbed = ({ value }) => {
	const { url } = value;

	return (
		<iframe
			src={url}
			title="Google Maps Embed"
			frameBorder="0"
			allow="clipboard-write; geolocation '*'"
			width="640" height="480"
			style={{ maxWidth: "100%" }}
			allowFullScreen
		/>
	)
}

PTGoogleMapsEmbed.propTypes = {
	value: PropTypes.shape({
		url: PropTypes.string.isRequired
	}).isRequired
}

export default PTGoogleMapsEmbed;
