import PropTypes from "prop-types";
import AudioPlayer from 'components/audio-player';
import { urlFor } from "helpers/helpers";
const SanityAudio = props => {
  const {
    options,
    darkMode,
    tracks
  } = props;
  const exhibitionId = props.exhibitionId || props?.exhibition?._ref || null;
  const exhibitionTitle = props.exhibitionTitle || props?.exhibition?.title || null;
  const formattedTracks = tracks?.map(track => {
    if (!track?.file?.asset?.url) {
      // TODO: why needed to be handled here? 🤔
      return null;
    }
    return {
      ...track,
      // doesn't hurt to have all the fields!
      id: track._id,
      exhibitionId: track?.exhibition?._ref,
      // if an audio track is associated with an exhibition, it will report that to analytics when played, if the overall playlist is missing one
      audio: track.file.asset.url,
      image: urlFor(track.image) || track.externalImage
    };
  });
  return (
    // TODO: this inline styling should probably be moved to a parent/wrapper, so this can be more reusable
    <div style={{
      marginBottom: "var(--spacing-xxs)"
    }} data-sentry-component="SanityAudio" data-sentry-source-file="sanity-audio.jsx">
			<AudioPlayer exhibitionId={exhibitionId} exhibitionTitle={exhibitionTitle} options={{
        ...options
      }} id={formattedTracks[0]?.id} darkMode={darkMode} tracks={formattedTracks} data-sentry-element="AudioPlayer" data-sentry-source-file="sanity-audio.jsx" />
		</div>
  );
};
SanityAudio.propTypes = {
  darkMode: PropTypes.bool,
  exhibition: PropTypes.shape({
    _ref: PropTypes.any,
    title: PropTypes.any
  }),
  exhibitionId: PropTypes.string,
  exhibitionTitle: PropTypes.string,
  options: PropTypes.shape({
    playerMode: PropTypes.string
  }),
  tracks: PropTypes.array
};
export default SanityAudio;